<template>
  <div>
    <v-row class="mb-8">
      <div cols="12" style="overflow:hidden" class="container_">
        <img
          src="../../../assets/images/Rectangle 1212 (7).png"
          alt=""
          height="500"
          width="100%"
          class="image"
          :class="$vuetify.breakpoint.xs ? 'top-img' : ''"
          :style="$vuetify.breakpoint.xs ? 'object-fit:cover' : ''"
        />
        <div class="overlay">
          <h2 class="absolute-h2">Case Study</h2>
        </div>
      </div>
    </v-row>

    <v-col cols="12" class="challenge-heading py-10">
      <v-spacer></v-spacer>
      <h2 style="color:#28A84A" class="text-center pb-8">
        Challenges with coldchain products
      </h2>
      <p
        :class="$vuetify.breakpoint.xs ? 'text-left' : 'text-center'"
        :style="
          $vuetify.breakpoint.xs
            ? 'width: 100%; margin-left: auto; margin-right: auto;'
            : 'width: 60%; margin-left: auto; margin-right: auto;'
        "
      >
        We discovered that each hand-off in the cold chain line opens the door
        for risk. Assumption of product integrity is not enough, without a
        reliable and consistent temperature monitoring and data logger,
        especially during storage, transit, and distribution to the final
        consumer.
      </p>
      <v-spacer></v-spacer>
    </v-col>
    <v-row
      cols="12"
      class="py-16"
      style="background: rgba(230, 236, 238, 0.36)"
      :style="
        $vuetify.breakpoint.xs ? 'padding:3rem 1rem' : 'padding:3rem 6rem'
      "
    >
      <v-col cols="12" sm="6">
        <h2 class="pb-8">Drugstoc Case Study</h2>
        <p :style="$vuetify.breakpoint.smAndDown ? 'width:100%' : 'width:85%'">
          DrugStoc improves on their cold chain facility and ensures
          uninterrupted storage of their temperature sensitive products DrugStoc
          was established with a vision to help healthcare providers fulfil
          their mandate for a healthier Africa. They constantly seek new ways to
          improve access to quality, non-counterfeit pharmaceutical products and
          services. They have a working supply chain management for regular
          pharmaceuticals but have experienced challenges with tracking
          temperature sensitive shipments in real time.
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        style="align-items: center;
    display: flex"
      >
        <img
          src="../../../assets/images/image 11.png"
          alt=""
          width="100%"
          height="300"
        />
      </v-col>
    </v-row>
    <div
      :style="
        $vuetify.breakpoint.xs ? 'padding:3rem 1rem' : 'padding:3rem 6rem'
      "
    >
      <!-- <v-row>
        <v-col cols="12" sm="6">
          <h2 class="py-6">coldchain Management challenges</h2>
          <p :style="!$vuetify.breakpoint.md ? 'width:80%' : ''">
            For many years, businesses have used data loggers which is an after
            effect solution that does not provide real time visibility during
            transit. Thus businesses have remained oblivious to the knowledge of
            storage temperatures of medication while in motion.
          </p>
        </v-col>
        <v-col class="px-4" cols="12" sm="6">
          <v-row>
            <v-col cols="1" v-show="$vuetify.breakpoint.mdAndUp">
              <img
                src="../../../assets/images/Group 1402.png"
                alt=""
                :style="$vuetify.breakpoint.md ? 'height:42rem' : ''"
              />
            </v-col>
            <v-col :style="$vuetify.breakpoint.md ? 'padding-left:2rem' : ''">
              <div
                class="right-div"
                :style="$vuetify.breakpoint.md ? 'margin-bottom:3.2rem' : ''"
              >
                <h4>Inability to track storage conditions while in transit</h4>
                <p>
                  Cold chain products need to be stored and transported within
                  fixed temperature ranges. Going out of this recommended
                  temperatures may lead to losses and in extreme cases may harm
                  the people who depend on them.
                </p>
              </div>
              <div
                class="right-div"
                :style="
                  $vuetify.breakpoint.md
                    ? 'margin-bottom:5.8rem'
                    : 'margin-bottom:6.4rem'
                "
              >
                <h4>Bulky nature of Ice Packs</h4>
                <p>
                  Effective space utilization is important in logistics. In cold
                  chain logistics, Ice packs take up valuable space and in some
                  cases are a source of additional weight. This space can be
                  used to move additional life saving medication but passive
                  means of cooling makes it entirely impossible to cool without
                  ice packs.
                </p>
              </div>
              <div class="right-div">
                <h4>Bulky nature of Ice Packs</h4>
                <p>
                  Effective space utilization is important in logistics. In cold
                  chain logistics, Ice packs take up valuable space and in some
                  cases are a source of additional weight. This space can be
                  used to move additional life saving medication but passive
                  means of cooling makes it entirely impossible to cool without
                  ice packs.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->

      <v-row :style="$vuetify.breakpoint.xs ? 'padding:4rem 1rem' : ''">
        <v-col cols="12" sm="6" class="prod-container">
          <h2>Why Use Gricd Cold Boxes?</h2>
          <p>
            IoT enabled 15 liter active cooling box to maintain stable internal
            temperature of up to -20° C for up to 24 hours.
          </p>
          <img src="../../../assets/images/mote-50 1.png" alt="" width="65%" />
        </v-col>
        <v-col cols="12" sm="6" class="right_">
          <img src="../../../assets/images/Group 1402.png" alt="" />
          <div>
            <div class="right-div">
              <h5>Monitor Remotely</h5>
              <p>
                Gricd boxes can be controlled and monitored in real-time,
                allowing health organizations take pro active decisions that
                reduce medication wastage.
              </p>
            </div>
            <div class="right-div">
              <h5>Get ahead of competitors</h5>
              <p class="btm-p">
                Access to new markets and market share. There is a large cold
                chain market that needs to be reached. You can breach that gap
                with our cold boxes.
              </p>
            </div>
            <div class="right-div">
              <h5>Trackable system</h5>
              <p>
                Ability to move premium commodity. We offer an IoT enabled,
                efficient and trackable system of conveying items that are
                dependent on cooling.
              </p>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-col
        cols="12"
        class="challenge-heading py-10"
        style="background: rgba(242, 245, 247, 0.46);"
      >
        <v-spacer></v-spacer>
        <h2 style="color:#28A84A" class="text-center pb-8">
          How we helped DrugStoc
        </h2>
        <p class="text-center">
          In October 2019, DrugStoc introduced the Frij box in their operations
          as their preferred cold chain equipment. The Frij box allows mobile
          storage space of up to 50 liters with an average 15mins to reach the
          required preset temperature of up to -20°C. The Frij box can last up
          to 24hours on a single charge and is accompanied by real-time
          temperature and location monitoring every step of the way.
        </p>
        <v-spacer></v-spacer>
      </v-col>
      <v-row class="py-12">
        <v-col>
          <div>
            <div class="circle"></div>
            <h3 class="" style="position: relative; bottom: 27px;">
              Optimising valuable space
            </h3>
          </div>
          <p>
            It has a maximum box capacity of either 15 Liters or 50 liters - two
            varying box sizes with a 4.5kg net weight. The box is very portable
            and allows for maximum use of its internal space.
          </p>
        </v-col>
        <v-col>
          <div class="square"></div>
          <h3 class="" style="position: relative; bottom: 27px;">
            Active cooling system
          </h3>
          <p>
            The Frij box is an active cooling system. Hence, it abolishes
            preconditioning time associated with passive systems. The time saved
            can be directed to other more productive activities.
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Case2',
  metaInfo() {
      return {
        script: [
          {
            type: 'application/ld+json',
            json: {
              '@context': 'http://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: 'Home',
                  item: 'https://gricd.com/',
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: 'Case Study',
                  item: 'https://gricd.com/about/case-studies',
                },
                {
                  '@type': 'ListItem',
                  position: 3,
                  name: 'Case Study: Juhel Pharmaceutical',
                  item: 'https://gricd.com/about/case-studies-2',
                },
              ],
            },
          },
        ],
      };
    },
};
</script>

<style lang="scss" scoped>
.circle {
  clip-path: circle(50% at 50% 50%);
  background: #b5ccbb;
  height: 28px;
  width: 28px;
  position: relative;
  right: 7px;
}
.square {
  width: 28px;
  height: 28px;
  background: #f1dbc1;
  transform: rotate(-42.61deg);
  position: relative;
  right: 7px;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 180%;
  color: #212529;
}
h4 {
  font-weight: bold;
  font-size: 20px;
  color: #212529;
  margin-bottom: 2rem;
}
// .right-div {
//   margin-bottom: 4.7rem;
// }

.right-div p {
  margin-bottom: 24%;
  @media (max-width: 1024px) {
    margin-bottom: 7%;
  }
}
.absolute-h2 {
  position: absolute;
  top: 14rem;
  left: 10rem;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  @media (max-width: 767px) {
    top: 11rem;
    left: 4rem;
    color: #28a84a;
  }
}
h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #212529;
}
.right_ {
  display: flex;
  column-gap: 2rem;
  padding: 0 5rem;
  img {
    height: 18.6rem;
    width: auto;
  }
  @media (max-width: 1024px) {
    padding: 0 1rem;
    column-gap: 1rem;
    img {
      height: 21.5rem;
    }
  }
}
.btm-p {
  @media (max-width: 1024px) {
    margin-bottom: 3.4rem !important;
  }
}
.prod-container {
  padding: 0.7rem 9rem 0.7rem 4rem;
  h2 {
    padding-bottom: 2rem;
  }
  p {
    padding-bottom: 3rem;
  }
  @media (max-width: 1024px) {
    padding: 0.7rem 1rem;
    p {
      padding-bottom: 1rem;
    }
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .right-div p {
    margin-bottom: 1.3rem;
  }

  .prod-container {
    padding: 0.7rem 3rem 0.7rem 3rem;
  }
  .btm-p {
    margin-bottom: 3.6rem !important;
  }
}
h1 {
  font-size: 2.4rem;
}
p.head {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: 1.1px;
  color: #141414;
}

.container_ {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0.5rem;
  background: black;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  h2 {
    color: white;
  }
}

.container_ .overlay {
  opacity: 1;
}
.container_ h2 {
  border-bottom: solid 4px #28a84a;
}
</style>
